@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Roboto+Mono:ital,wght@1,700&display=swap');

:root {
  --accent: #59CD90;
  --alt-accent: #81D9AA;

  --background: #1B1B1E;
  --alt-bg: #111113;
}

html {
  background-color: var(--background);
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
}

h1 {
  margin-bottom: 5px;
}

.App-logo {
  height: 35vmin;
  pointer-events: none;
  border-radius: 100%;
}

.App-header {
  display: flex;
  align-self: center;
  justify-self: center;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header > p {
  font-size: calc(8px + 2vmin);
}

nav {
  flex-direction: row;
  align-items: center;
  padding: 1rem;
}

nav>a {
  color: white;
  text-decoration: none;
  letter-spacing: 0.2rem;
  padding: 1rem;
  color: var(--accent);

  font-size: x-large;
  font-family: 'Roboto Mono', monospace;
}

.App-link {
  color: var(--accent);
}

.social>* {
  margin: 0.25rem;
}

.Projects-view {
  display: flex;
  width: 100%;
  align-self: center;
  justify-self: center;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Project {
  width: 70%;
  background-color: var(--alt-bg);
  padding: 1rem;
  margin: 1rem;
  border-radius: 2rem;
}

.Project > p {
  @media screen and (min-width: 1000px) {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.appStoreLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
}

#play-badge {
  height: 100px;
}

#apple-badge {
  height: 70px;
}

#testflight-badge {
  height: 75px;
}

.code {
  font-family: monospace;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  margin: 5px;
  line-height: 50px;
  border-radius: 10px;
}